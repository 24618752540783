import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AccountManagement.css';

function AccountManagement({ user }) {
  const [newUsername, setNewUsername] = useState('');
  const [newName, setNewName] = useState('');
  const [newGroup, setNewGroup] = useState('');
  const [newSheet, setNewSheet] = useState('');
  const [newStatuses, setNewStatuses] = useState('');
  const [message, setMessage] = useState('');
  const [usersList, setUsersList] = useState([]);

  // Загружаем список пользователей при загрузке компонента
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/users'); // Эндпоинт для получения списка пользователей
        setUsersList(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);
  
  // Добавление нового пользователя
  const handleAddUser = async () => {
    try {
      const response = await axios.post('/add-user', {
        userId: user.id,
        username: newUsername,
        name: newName,
        group: newGroup,
        sheet: newSheet,
        statuses: newStatuses.split(',')
      });

      if (response.data === 'User added successfully') {
        setMessage('Новый пользователь успешно добавлен');
        setNewUsername('');
        setNewName('');
        setNewGroup('');
        setNewSheet('');
        setNewStatuses('');
        setUsersList([...usersList, { username: newUsername, name: newName, group: newGroup }]); // Обновляем список
      }
    } catch (error) {
      console.error('Error adding user:', error);
      setMessage('Ошибка при добавлении пользователя');
    }
  };

  // Удаление пользователя с подтверждением
  const handleDeleteUser = async (usernameToDelete) => {
    // Показываем окно подтверждения
    const confirmed = window.confirm(`Вы уверены, что хотите удалить пользователя ${usernameToDelete}?`);
    if (!confirmed) {
      return; // Если пользователь не подтвердил удаление, выходим из функции
    }

    try {
      const response = await axios.post('/delete-user', {
        userId: user.id,
        username: usernameToDelete
      });

      if (response.data === 'User deleted successfully') {
        setMessage('Пользователь успешно удален');
        setUsersList(usersList.filter(u => u.username !== usernameToDelete)); // Обновляем список
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setMessage('Ошибка при удалении пользователя');
    }
  };

  return (
    <div className="account-management">
      <h2>Управление аккаунтами</h2>

      {/* Форма добавления нового пользователя */}
      <div>
        <label>Логин:</label>
        <input
          type="text"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          placeholder="Введите логин"
        />
      </div>
      <div>
        <label>Имя:</label>
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder="Введите имя"
        />
      </div>
      <div>
        <label>Группа:</label>
        <input
          type="text"
          value={newGroup}
          onChange={(e) => setNewGroup(e.target.value)}
          placeholder="Введите группу"
        />
      </div>
      <div>
        <label>Лист в Google Sheets:</label>
        <input
          type="text"
          value={newSheet}
          onChange={(e) => setNewSheet(e.target.value)}
          placeholder="Введите название листа"
        />
      </div>
      <div>
        <label>Статусы (через запятую):</label>
        <input
          type="text"
          value={newStatuses}
          onChange={(e) => setNewStatuses(e.target.value)}
          placeholder="Введите статусы"
        />
      </div>
      <button onClick={handleAddUser}>Добавить пользователя</button>

      {message && <p>{message}</p>}

      {/* Список существующих пользователей */}
      <h3>Список пользователей</h3>
      <ul>
        {usersList.map((userItem, index) => (
          <li key={index}>
            <span>{userItem.username} ({userItem.name}) - {userItem.group}</span>
            <button onClick={() => handleDeleteUser(userItem.username)}>Удалить</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AccountManagement;
