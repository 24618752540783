import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Login from './Login';
import SearchPage from './SearchPage';
import TopPage from './TopPage';
import WeeklyStats from './WeeklyStats';
import AccountManagement from './AccountManagement';
import NotificationManagement from './NotificationManagement';
import Notifications from './Notifications';
import NewsPage from './NewsPage';
import './App.css';
import './tabpanel.css';
import './style.css';
import logos from './assets/logos.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const wsRef = useRef(null);

  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }

    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      setActiveTab(parseInt(savedTab, 10));
    }

    const connectWebSocket = () =>{
      if (!wsRef.current) {
        console.log('Opening WebSocket connection');
        const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
        const wsHost = window.location.hostname === 'localhost' ? 'localhost:3030' : 'thebase2024.ru';
        wsRef.current = new WebSocket(`${wsProtocol}://${wsHost}/ws/`);
  
        wsRef.current.onopen = () => {
          console.log('WebSocket connection opened');
        };
  
        wsRef.current.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
  
        wsRef.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log('WebSocket message:', data);
  
          switch (data.type) {
            case 'news':
              toast.info(<div dangerouslySetInnerHTML={{ __html: data.data.title }} />);
              break;
            case 'notification':
              toast.info(<div dangerouslySetInnerHTML={{ __html: data.message }} />);
              break;
            default:
              break;
          }
        };
    }

      wsRef.current.onclose = (event) => {
        console.log('WebSocket connection closed');
        if (event.wasClean) {
          console.log(`Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
        } else {
          console.error('Connection closed with error');
          setTimeout(() => {
            connectWebSocket();
          }, 3000);
        }
        wsRef.current = null;
      };
    }
    connectWebSocket();
    return () => {
      if (wsRef.current) {
        console.log('Closing WebSocket connection');
        wsRef.current.close();
        wsRef.current = null;
      }
    };
  }, []);

  const handleTabSelect = (index) => {
    setActiveTab(index);
    localStorage.setItem('activeTab', index);
  };

  const login = async (username, rememberMe) => {
    try {
      const response = await axios.post('/login', { username });
      if (response.data.success) {
        setUser(response.data.user);
        setErrorMessage('');
        if (rememberMe) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
        }
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('Ошибка входа');
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  return (
    <div className="App">
      <ToastContainer />
      <div className='header'>
        {user && (
          <div className="notifications-logout">
            <Notifications user={user} ws={wsRef.current} />
            <span className="user-info">Пользователь: {user.name}</span>
            <button onClick={logout}>Выйти</button>
          </div>
        )}
      </div>
      <div className='logo-container'>
        <img src={logos} alt="Logo" className='logo' />
      </div>
      <div className="menu">
        {!user ? (
          <Login onLogin={login} errorMessage={errorMessage} />
        ) : (
          <Tabs selectedIndex={activeTab} onSelect={handleTabSelect}>
            <TabList>
              <Tab>Новости</Tab>
              <Tab>Поиск ЦОК</Tab>
              <Tab>Топ ЦОКи</Tab>
              <Tab>Статистика</Tab>
              {user.id === 1 && <Tab>Управление аккаунтами</Tab>}
              {user.id === 1 && <Tab>Управление уведомлениями</Tab>}
            </TabList>
            <TabPanel>
              <NewsPage user={user} ws={wsRef.current} />
            </TabPanel>
            <TabPanel>
              <SearchPage user={user} />
            </TabPanel>
            <TabPanel>
              <TopPage />
            </TabPanel>
            <TabPanel>
              <WeeklyStats user={user} />
            </TabPanel>
            {user.id === 1 && (
              <TabPanel>
                <AccountManagement user={user} />
              </TabPanel>
            )}
            {user.id === 1 && (
              <TabPanel>
                <NotificationManagement user={user} />
              </TabPanel>
            )}
          </Tabs>
        )}
      </div>
    </div>
  );
}

export default App;
