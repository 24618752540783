import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './NewsPage.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const NewsPage = ({ user, ws }) => {
    const [news, setNews] = useState([]);
    const [comments, setComments] = useState({});
    const [reply, setReply] = useState({ commentId: null, text: '' });
    const [newTitle, setNewTitle] = useState('');
    const [newContent, setNewContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const newsPerPage = 3;
    const currentUserId = user.id;

    useEffect(() => {
        const fetchNews = async () => {
            const response = await axios.get('/news');
            setNews(response.data);
        };

        const handleWsMessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'news') {
                setNews((prevNews) => [data.data, ...prevNews]);
            } else if (data.type === 'news-delete') {
                setNews((prevNews) => prevNews.filter(news => news.id !== data.data.id));
            } else if (data.type === 'comment') {
                setNews((prevNews) =>
                    prevNews.map((newsItem) =>
                        newsItem.id === data.data.newsId
                            ? { ...newsItem, comments: [...newsItem.comments, data.data.newComment] }
                            : newsItem
                    )
                );
            } else if (data.type === 'reply') {
                setNews((prevNews) =>
                    prevNews.map((newsItem) =>
                        newsItem.id === data.data.newsId
                            ? {
                                ...newsItem,
                                comments: newsItem.comments.map((commentItem, index) =>
                                    index === data.data.commentId
                                        ? { ...commentItem, replies: [...commentItem.replies, data.data.newReply] }
                                        : commentItem
                                ),
                            }
                            : newsItem
                    )
                );
            }
        };

        if (ws) {
            ws.addEventListener('message', handleWsMessage);
        }

        fetchNews();

        return () => {
            if (ws) {
                ws.removeEventListener('message', handleWsMessage);
            }
        };
    }, [ws]);



    const addComment = async (newsId) => {
        await axios.post(`/news/${newsId}/comment`, { userId: currentUserId, comment: comments[newsId] || '' });
        setComments(prevComments => ({...prevComments, [newsId]: ''}));
    };

    const addReply = async (newsId, commentId) => {
        await axios.post(`/news/${newsId}/comment/${commentId}/reply`, { userId: currentUserId, comment: reply.text });
        setReply({ commentId: null, text: '' });
    };

    const addNews = async () => {
        await axios.post('/news', { title: newTitle, content: newContent, userId: currentUserId });
        setNewTitle('');
        setNewContent('');
        const response = await axios.get('/news');
        setNews(response.data);
    };

    const deleteNews = async (newsId) => {
        await axios.delete(`/news/${newsId}`, { data: { userId: currentUserId } });
        const response = await axios.get('/news');
        setNews(response.data);
    };

    const toggleReplyInput = (commentId) => {
        if (reply.commentId === commentId) {
            setReply({ commentId: null, text: '' });
        } else {
            setReply({ commentId, text: '' });
        }
    };

    const indexOfLastNews = currentPage * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;

    const currentNews = news
        .slice()
        .sort((a, b) => b.id - a.id)
        .slice(indexOfFirstNews, indexOfLastNews);

    const totalPages = Math.ceil(news.length / newsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [isCreating, setIsCreating] = useState(false);
    const toggleCreateNews = () => {
        setIsCreating(prev=>!prev);
    }

    return (
        <div className='news-container'>
            {user.id === 1 && (
                <button onClick={toggleCreateNews} style={{marginBottom: '15px'}}>
                    {isCreating ? 'Отмена' : 'Создать новость'}
                </button>
            )}
            {isCreating && user.id === 1 && (
                <div className='news-wrapper'>
                    <h2>Добавить новость</h2>
                    <input className='news-creation'
                        type="text"
                        placeholder="Заголовок"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                    /><br />
                    <ReactQuill
                        theme="snow"
                        value={newContent}
                        onChange={setNewContent}
                        placeholder="Введите текст новости..."
                    /><br />
                    <button onClick={addNews}>Добавить новость</button>
                </div>
            )}
            <div className={`${user.id === 1 ? 'news-container__bottom' : ''}`}>
                {currentNews.map(item => (
                    <div className="news-item" key={item.id}>
                        <div className='news-item__title-wrapper'>
                            <h2 className="news-title">{item.title}</h2>
                            <span className="timestamp">
                                {new Date(item.timestamp).getFullYear() === new Date().getFullYear()
                                    ? new Date(item.timestamp).toLocaleString('ru-RU', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })
                                    : new Date(item.timestamp).toLocaleString('ru-RU', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })
                                }
                            </span>
                        {user.id === 1 && (
                            <button onClick={() => deleteNews(item.id)} className="delete-button">
                                🗑️
                            </button>
                        )}
                        </div>
                        <div
                            className="news-content"
                            dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>
                        <div className="comments-section">
                            {item.comments.map((c, index) => (
                                <div className="comment" key={index}>
                                    <div className="comment-header">
                                        <span className={c.userId === currentUserId ? 'user-name user-current' : 'user-name user-other'}>{c.userName}</span>
                                        <span className="timestamp timestamp-left">
                                            {new Date(c.timestamp).getFullYear() === new Date().getFullYear()
                                                ? new Date(c.timestamp).toLocaleString('ru-RU', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                })
                                                : new Date(c.timestamp).toLocaleString('ru-RU', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                })
                                            }
                                        </span>
                                    </div>
                                    <p style={{ margin: 0, marginBottom: '20px' }}>{c.comment}</p>
                                    <button className="reply-button" onClick={() => toggleReplyInput(index)}>Ответить</button>
                                    {c.replies.map((r, replyIndex) => (
                                        <div className='comment-wrapper'>
                                            <div className="comment-reply" key={replyIndex}>
                                                <span className={r.userId === currentUserId ? 'user-name user-current' : 'user-name user-name-other'}>{r.userName}</span>: {r.comment}
                                                <span className="timestamp">
                                                    {new Date(r.timestamp).getFullYear() === new Date().getFullYear()
                                                        ? new Date(r.timestamp).toLocaleString('ru-RU', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        })
                                                        : new Date(r.timestamp).toLocaleString('ru-RU', {
                                                            day: '2-digit',
                                                            month: '2-digit',
                                                            year: 'numeric',
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        })
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                    {reply.commentId === index && (
                                        <div>
                                            <input
                                                className="reply-input"
                                                type="text"
                                                placeholder="Ваш ответ..."
                                                value={reply.text}
                                                onChange={(e) => setReply({ ...reply, text: e.target.value })}
                                            />
                                            <button className="reply-button" onClick={() => addReply(item.id, index)}>Отправить</button>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <input
                                className="comment-input"
                                type="text"
                                value={comments[item.id] || ''}
                                onChange={(e) => setComments({...comments, [item.id]: e.target.value})}
                                placeholder="Ваш комментарий..."
                            />
                            <button className="comment-button" onClick={() => addComment(item.id)}>Добавить комментарий</button>
                        </div>
                    </div>
                ))}
            <div className="pagination">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={page === currentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}
            </div>
            </div>
        </div>
    );
};

export default NewsPage;
