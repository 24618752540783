import React, { useEffect, useState } from 'react';
import './TopPage.css';

const subjectCodes = {
  '01': 'Русский язык',
  '02.1': 'Математика',
  '02.2': 'Алгебра',
  '02.3': 'Геометрия',
  '02.4': 'Вероятность и статистика',
  '02.5': 'Алгебра и начала математического анализа',
  '03': 'Физика',
  '04': 'Химия',
  '05': 'Информатика',
  '06': 'Биология',
  '07': 'История',
  '07.1': 'История России',
  '07.2': 'Всеобщая история',
  '08': 'География',
  '09': 'Английский язык',
  '12': 'Обществознание',
  '15': 'Окружающий мир',
  '16': 'Изобразительное искусство',
  '17': 'ОБЖ',
  '18': 'Литературное чтение',
  '19': 'Литература',
  '20': 'Технология',
  '56': 'Музыка',
  '902.2': 'Алгебра. Углубленный уровень',
  '902.3': 'Геометрия. Углубленный уровень',
  '902.4': 'Вероятность и статистика. Углубленный уровень',
  '903': 'Физика. Углубленный уровень',
  '904': 'Химия. Углубленный уровень',
  '905': 'Информатика. Углубленный уровень',
  '907': 'История. Углубленный уровень',
  '912': 'Обществознание. Углубленный уровень',
};

function TopPage() {
  const [subjects, setSubjects] = useState({});
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const fetchSubjects = () => {
    fetch('/subjects')
      .then(response => response.json())
      .then(data => {
        const subjectsData = {};

        data.forEach(row => {
          const code = row[1].split('-')[0];
          const subjectName = subjectCodes[code];
          if (subjectName) {
            if (!subjectsData[subjectName]) {
              subjectsData[subjectName] = [];
            }
            subjectsData[subjectName].push(row[1]);
          }
        });

        setSubjects(subjectsData);
      })
      .catch(error => console.error('Error fetching subjects:', error));
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  const handleSubjectClick = (subject) => {
    setSelectedSubject(subject);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className='top-cok-page'>
      <h2>Топ ЦОКи</h2>
      <div className='table-container'>
        <table>
          <thead>
            <tr>
              <th>Предмет</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(subjects).map((subject, index) => (
              <tr key={index} onClick={() => handleSubjectClick(subject)} className="clickable-row">
                <td>{subject}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showPopup && (
        <div className='popup'>
          <div className='popup-inner'>
            <h3>{selectedSubject}</h3>
            <button className='close-button' onClick={closePopup}>×</button>
            <ul>
              {subjects[selectedSubject].map((number, index) => (
                <li key={index}>{number}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default TopPage;
