import React, { useState } from 'react';

function Login({ onLogin, errorMessage }) {
  const [username, setUsername] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(username, rememberMe);
  };

  return (
    <form onSubmit={handleSubmit} className='login'>
      <h2>Введите ваш логин:</h2>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      />
      <div className="checkbox-container">
        <input
          type="checkbox"
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
        />
        <label>Запомнить меня</label>
      </div>
      <button type="submit">Login</button>
      {errorMessage && <p className="error">{errorMessage}</p>}
    </form>
  );
}

export default Login;
